import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default el => {

    const button = $(el)
        .find('button')
        .get(0);
    const menu = button.nextElementSibling;

    let isExpanded = false;
    let tl = null;

    const expand = () => {
        if (isExpanded) {
            return;
        }
        isExpanded = true;
        button.setAttribute('aria-expanded', 'true');
        menu.hidden = false;
        button.focus();
        if (tl) {
            tl.kill();
            gsap.killTweensOf(menu);
        }
        tl = gsap.timeline({
            onComplete() {
                tl = null;
            }
        })
            .fromTo(menu, { y: -10 }, {
                y: 0,
                duration: 0.3,
                ease: 'Back.easeOut'
            }, 0)
            .fromTo(menu, { opacity: 0 }, {
                opacity: 1,
                duration: 0.3
            }, 0);
    };

    const collapse = (tween = true) => {
        if (!isExpanded) {
            return;
        }
        isExpanded = false;
        button.setAttribute('aria-expanded', 'false');
        const focusedElement = document.activeElement || null;
        if (menu.contains(focusedElement)) {
            button.focus();
        }
        if (tl) {
            tl.kill();
            gsap.killTweensOf(menu);
        }
        if (!tween) {
            menu.hidden = true;
            tl = null;
            return;
        }
        tl = gsap.timeline({
                onComplete() {
                    menu.hidden = true;
                    tl = null;
                }
            })
            .to(menu, {
                y: -10,
                duration: 0.3,
                ease: 'Quad.easeIn'
            }, 0)
            .to(menu, {
                opacity: 0,
                duration: 0.3
            }, 0);
    };

    const toggle = () => {
        if (isExpanded) {
            collapse();
        } else {
            expand();
        }
    };

    $(button)
        .on('click', toggle);

    const onBodyClick = e => {
        if (!isExpanded || e.target === button || button.contains(e.target) || e.target === menu || menu.contains(e.target)) {
            return;
        }
        collapse();
    };

    const onBodyKeyUp = e => {
        if (!isExpanded || e.key !== 'Escape') {
            return;
        }
        collapse();
    };

    const $body = $('body');
    $body.on('click focusin', onBodyClick);
    $body.on('keyup', onBodyKeyUp);

    let observer = new IntersectionObserver(([{ isIntersecting }]) => {
        if (isExpanded && !isIntersecting) {
            collapse();
        }
    });
    observer.observe(button);

    const onBreakpoint = () => {
        if (!isExpanded) {
            return;
        }
        if (!button.offsetParent) {
            collapse(false);
        }
    };

    Viewport.on('breakpoint', onBreakpoint);

    return {
        destroy() {
            $(button)
                .off('click');
            $body.off('click focusin', onBodyClick);
            $body.off('keyup', onBodyKeyUp);
            observer.disconnect();
            observer = null;
            Viewport.off('breakpoint', onBreakpoint);
            collapse(false);
        }
    };

};
