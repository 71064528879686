import Cleave from 'cleave.js';
import { loadFlatpickr } from '../lib/async-bundles';

export default el => {

    const input = el.querySelector('input[type="text"]');
    
    const { min: dateMin = null, max: dateMax = null, format } = el.dataset;
    
    let cleave;
    let picker;

    const onInputFocus = e => {
        const { value } = e.target;
        if (!value) {
            input.placeholder = format;
        }
    };

    const onInputBlur = () => {
        input.placeholder = ' ';
    };

    const onInputKeyUp = e => {
        const { value } = e.target;
        if (!value && document.activeElement === input) {
            input.placeholder = format;
        }
    };

    cleave = new Cleave(input, {
        date: true,
        delimiter: '.',
        datePattern: ['d', 'm', 'Y'],
        dateMin,
        dateMax
    });

    input.addEventListener('focusin', onInputFocus);
    input.addEventListener('focusout', onInputBlur);
    input.addEventListener('keyup', onInputKeyUp);

    // Load flatpickr
    loadFlatpickr(({ default: flatpickr }) => {
        // The format for the minDate and maxDate params follow the dateFormat, so need to reformat them from the Y-m-d
        const ymdToDmy = ymd => {
            if (!ymd) return null;
            const parts = ymd.split('-');
            return `${parts[2]}.${parts[1]}.${parts[0]}`;
        };
        picker = flatpickr(input, {
            showMonths: 1,
            allowInput: true,
            dateFormat: 'd.m.Y',
            minDate: ymdToDmy(dateMin),
            maxDate: ymdToDmy(dateMax)
        });
    });

    return {
        destroy() {
            input.removeEventListener('focusin', onInputFocus);
            input.removeEventListener('focusout', onInputBlur);
            input.removeEventListener('keyup', onInputKeyUp);
            if (cleave) {
                cleave.destroy();
                cleave = null;
            }
            if (picker) {
                picker.destroy();
                picker = null;
            }
        }
    };

};
