import { animatedScroll } from '../lib/helpers';

export default el => {

    const onClick = e => {
        e.preventDefault();
        animatedScroll(el.getAttribute('href'));
    };

    el.addEventListener('click', onClick);

    return {
        destroy() {
            el.removeEventListener('click', onClick);
        }
    };

};
