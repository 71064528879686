import Slider from './Slider';

export default el => {

    const sliderEl = el.querySelector('[data-slider]');
    const pageHeader = document.getElementById('header');

    let slider;
    let flickityInstance;

    const onSlideChange = flkty => {
        flickityInstance = flkty;
        const selectedSlide = flkty.selectedCell.element;
        const { theme } = selectedSlide.dataset;
        if (pageHeader) {
            if (theme === 'green-dark') {
                pageHeader.classList.add('transparent');
            } else {
                pageHeader.classList.remove('transparent');
            }
            const pageHeaderBg = pageHeader.querySelector('[data-bg]');
            if (pageHeaderBg) {
                const pageHeaderBgClassList = pageHeaderBg.className.split(/\s+/).filter(className => !className.startsWith('bg-'));
                pageHeaderBgClassList.push(`bg-${theme}`);
                pageHeaderBg.className = pageHeaderBgClassList.join(' ');
            }
        }
        const sliderWrapperClassList = sliderEl.parentNode.className.split(/\s+/).filter(className => !className.startsWith('theme-'));
        sliderWrapperClassList.push(`theme-${theme}`);
        sliderEl.parentNode.className = sliderWrapperClassList.join(' ');
        flkty.cells.forEach(cell => {
            cell.element.querySelectorAll('a,button').forEach(link => {
                if (cell.element === selectedSlide) {
                    link.removeAttribute('tabindex');
                } else {
                    link.setAttribute('tabindex', '-1');
                }
            });
        });
        const dots = [...el.querySelectorAll('[data-dot]')];
        dots.forEach((dot, index) => {
            if (index === flkty.selectedIndex) {
                dot.classList.add('active');
            } else {
                dot.classList.remove('active');
            }
        });
    };

    const onDotClick = e => {
        if (!flickityInstance) {
            return;
        }
        const index = parseInt(e.currentTarget.dataset.dot, 10);
        flickityInstance.selectCell(index);
    };

    const maybeCreateSlider = () => {
        if (!sliderEl) {
            return;
        }
        slider = Slider(el, {
            adaptiveHeight: true,
            accessibility: true,
            autoPlay: 2500,
            onChange: onSlideChange,
            onReady: onSlideChange
        });
        el.querySelectorAll('button[data-dot]').forEach(dot => {
            dot.addEventListener('click', onDotClick);
        });
    };

    const destroySlider = () => {
        if (!slider) {
            return;
        }
        slider.destroy();
        slider = null;
        el.querySelectorAll('button[data-dot]').forEach(dot => {
            dot.removeEventListener('click', onDotClick);
        });
    };

    const init = () => {
        maybeCreateSlider();
    };

    const destroy = () => {
        destroySlider();
    };

    return {
        init,
        destroy
    };

};
