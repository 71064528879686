import gsap from 'gsap';
import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    lazySizes.init();

    $(document.body).on('change', 'input,textarea,select', e => {
        if (e.triggerTarget.value) {
            e.triggerTarget.classList.add('has-value');
        } else {
            e.triggerTarget.classList.remove('has-value');
        }
    });
};

require('doc-ready')(init);
