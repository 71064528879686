import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import { clearTimelineProps } from '../lib/helpers';

gsap.registerPlugin(ScrollToPlugin);

export default el => {

    const $el = $(el);
    const toggle = $el
        .find('button[aria-expanded]')
        .get(0);
    const cart = $el.find('[data-cart]').get(0);
    const menu = toggle.nextElementSibling;

    let isOpen = false;
    let tl;

    const afterClose = () => {
        menu.hidden = true;
        document.body.classList.remove('menu-open');
        document.body.classList.remove('menu-opening');
        if (cart) {
            cart.removeAttribute('tabindex');
        }
        if (tl) {
            clearTimelineProps(tl);
            tl.kill();
            tl = null;
        }
    };

    const createTl = () => {
        if (tl) {
            return;
        }
        const backdrop = menu.querySelector('[data-backdrop]');
        toggle.classList.add('text-black');
        const toggleColor = window.getComputedStyle(toggle).color;
        toggle.classList.remove('text-black');
        const toggleSpans = toggle.querySelectorAll('span[class*="bg-"]');
        tl = gsap.timeline({
            paused: true,
            onReverseComplete: afterClose
        })
            .to(backdrop, { backdropFilter: 'blur(3px)', duration: 0.5 }, 0)
            .to(toggle, { color: toggleColor, duration: 0.3, ease: 'none' }, 0.25)
            .to(toggleSpans, { backgroundColor: toggleColor, duration: 0.3, ease: 'none' }, 0.25)
            .fromTo(backdrop, { opacity: 0 }, { opacity: 1, duration: 0.5 }, 0)
            .fromTo(menu.firstElementChild, { xPercent: 100 }, {
                xPercent: 0,
                duration: 1,
                ease: 'Quint.easeInOut'
            }, 0)
            .fromTo(menu.firstElementChild.firstElementChild, { xPercent: -100 }, {
                xPercent: 0,
                duration: 1,
                ease: 'Quint.easeInOut'
            }, 0)
            .add(() => {
                if (isOpen) {
                    document.body.classList.add('menu-open');
                } else {
                    document.body.classList.remove('menu-open');
                }
            }, 0.35);
    };

    const open = () => {
        if (isOpen) {
            return;
        }
        const headerBar = document.getElementById('header').firstElementChild;
        const headerBarFixed = window.getComputedStyle(headerBar).position === 'fixed';
        if (!headerBarFixed && window.scrollY > 0) {
            gsap.to(window, { scrollTo: 0, duration: 0.3, onComplete: open });
            return;
        }
        document.body.classList.add('menu-opening');
        isOpen = true;
        toggle.setAttribute('aria-expanded', 'true');
        menu.hidden = false;
        Viewport.lockTabbing(el, toggle);
        if (cart) {
            cart.setAttribute('tabindex', '-1');
        }
        createTl();
        tl.timeScale(1).play();
    };

    const close = (tween = true) => {
        if (!isOpen) {
            return;
        }
        isOpen = false;
        toggle.setAttribute('aria-expanded', 'false');
        toggle.focus();
        Viewport.releaseTabbing(toggle);
        if (tween) {
            createTl();
            tl.timeScale(1.5).reverse();
        } else {
            tl.pause(0);
            afterClose();
        }
    };

    const onToggleClick = () => {
        if (isOpen) {
            close();
        } else {
            open();
        }
    };

    const onBodyKeyUp = e => {
        if (!isOpen || e.key !== 'Escape') {
            return;
        }
        e.stopPropagation();
        e.stopImmediatePropagation();
        close();
    };

    const onBodyClickOrFocus = e => {
        const alert = document.querySelector('[data-component="Alert"]');
        if (!isOpen || e.target === menu.firstElementChild || menu.firstElementChild.contains(e.target) || e.target === toggle || toggle.contains(e.target) || (alert && (e.target === alert || alert.contains(e.target)))) {
            return;
        }
        close();
    };

    toggle.addEventListener('click', onToggleClick);
    document.body.addEventListener('keyup', onBodyKeyUp);
    document.body.addEventListener('click', onBodyClickOrFocus);

    return {
        destroy() {
            toggle.removeEventListener('click', onToggleClick);
            document.body.removeEventListener('keyup', onBodyKeyUp);
            document.body.removeEventListener('click', onBodyClickOrFocus);
            close(false);
        }
    };

};
