import gsap from 'gsap';

export default el => {

    const button = el.querySelector('button');
    const content = button.nextElementSibling;

    let isExpanded = false;

    const collapse = () => {
        if (!isExpanded) {
            return;
        }
        isExpanded = false;
        button.setAttribute('aria-expanded', 'false');
        gsap.to(content, {
            height: 0,
            duration: 0.3,
            onComplete() {
                content.hidden = true;
            }
        });
    };

    const expand = () => {
        if (isExpanded) {
            return;
        }
        isExpanded = true;
        button.setAttribute('aria-expanded', 'true');
        content.hidden = false;
        const { height } = content.getBoundingClientRect();
        gsap.fromTo(content, { height }, {
            height: 'auto',
            duration: 0.3
        });
    };

    const toggle = () => {
        if (isExpanded) {
            collapse();
        } else {
            expand();
        }
    };

    button.addEventListener('click', toggle);

    return {
        destroy() {
            button.removeEventListener('click', toggle);
        }
    };

};
