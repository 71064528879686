/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const DOM_CHANGED = 'DOM_CHANGED';
export const MENU_CLOSE = 'MENU_CLOSE';
export const MENU_OPEN = 'MENU_OPEN';
export const PROGRAMMATIC_SCROLL_START = 'PROGRAMMATIC_SCROLL_START';
export const PROGRAMMATIC_SCROLL_END = 'PROGRAMMATIC_SCROLL_END';
export const ALERT_HIDDEN = 'ALERT_HIDDEN';
