import gsap from 'gsap';
import { loadFlatpickr } from '../lib/async-bundles';
import Viewport from '../core/Viewport';

export default (el, props) => {

    const input = el.querySelector('input');
    const fromValue = el.querySelector('[data-from]');
    const toValue = el.querySelector('[data-to]');

    let shader = document.querySelector('[data-flatpickr-shader]');

    const {
        fromPlaceholder = '',
        toPlaceholder = '',
        minDate = null,
        maxDate = null
    } = props || {};

    let Flatpickr;
    let picker;
    let showMonths = 1;
    let isOpen = false;

    const fromInput = el.querySelector('input[type="hidden"][data-input-from]');
    const toInput = el.querySelector('input[type="hidden"][data-input-to]');

    const defaultFromDate = fromInput.value;
    const defaultToDate = toInput.value;
    const defaultDate = [];
    if (defaultFromDate) {
        defaultDate.push(defaultFromDate);
        if (defaultToDate) {
            defaultDate.push(defaultToDate);
        }
    }

    const isSmall = () => ['l', 'lp', 'xl'].indexOf(Viewport.breakpoint.name) === -1;

    const numMonths = () => (['m', 'mp', 'l', 'lp', 'xl'].indexOf(Viewport.breakpoint.name) > -1 ? 2 : 1);

    const destroyPickr = () => {
        if (picker) {
            picker.destroy();
            picker = null;
        }
        if (shader) {
            document.body.removeChild(shader);
            shader = null;
        }
    };

    const initPickr = () => {
        destroyPickr();
        picker = Flatpickr(input, {
            showMonths: numMonths(),
            allowInput: false,
            disableMobile: false,
            mode: 'range',
            dateFormat: 'd.m.Y',
            defaultDate,
            onChange(selectedDates) {
                const [fromDate, toDate] = selectedDates;
                fromInput.value = fromDate ? picker.formatDate(fromDate, 'd-m-Y') : '';
                toInput.value = fromDate && toDate ? picker.formatDate(toDate, 'd-m-Y') : '';
                fromValue.textContent = fromDate ? picker.formatDate(fromDate, 'd.m.Y') : fromPlaceholder;
                toValue.textContent = fromDate && toDate ? picker.formatDate(toDate, 'd.m.Y') : toPlaceholder;
                if (fromDate) {
                    fromValue.classList.add('has-value');
                } else {
                    fromValue.classList.remove('has-value');
                }
                if (fromDate && toDate) {
                    toValue.classList.add('has-value');
                } else {
                    toValue.classList.remove('has-value');
                }
            },
            onOpen() {
                isOpen = true;
                if (!shader) {
                    shader = document.createElement('div');
                    shader.setAttribute('class', 'fixed full bg-black-jet z-[99998] opacity-0');
                    document.body.appendChild(shader);
                }
                gsap.set(shader, { opacity: 0 });
                shader.removeAttribute('hidden');
                gsap.to(shader, { opacity: 0.25, duration: 0.3, ease: 'power2.inOut' });
            },
            onClose() {
                isOpen = false;
                gsap.to(shader, {
                    opacity: 0,
                    duration: 0.3,
                    ease: 'power2.out',
                    onComplete: () => {
                        shader.setAttribute('hidden', '');
                    }
                });
            },
            minDate,
            maxDate
        });
        if (isOpen) {
            picker.open();
        }
    };

    let wasSmall = isSmall();
    let prevNumMonths = numMonths();

    const onBreakpoint = () => {
        if (!Flatpickr) {
            return;
        }
        if (wasSmall !== isSmall() || prevNumMonths !== numMonths()) {
            initPickr();
        }
        wasSmall = isSmall();
        prevNumMonths = numMonths();
    };

    loadFlatpickr(({ default: flatpickr }) => {
        Flatpickr = flatpickr;
        initPickr();
    });

    Viewport.on('breakpoint', onBreakpoint);

    return {
        destroy() {
            Viewport.on('breakpoint', onBreakpoint);
            destroyPickr();
        }
    };

};
