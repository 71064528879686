import gsap from 'gsap';
import Viewport from '../core/Viewport';
import Components from '../core/Components';
import superagent from '../core/request';

export default btn => {

    const modal = document.getElementById(btn.getAttribute('aria-controls'));
    const closeBtn = modal.querySelector('button[data-closebtn]');
    const content = modal.querySelector('[data-content]');
    const modalHtml = content.innerHTML;

    const { url } = modal.dataset;

    let modalIsOpen = false;
    let tl;
    let request;

    const getHtml = () => {
        if (request) {
            return;
        }
        request = superagent.get(url);
        request
            .then(({ status, text }) => {
                if (status !== 200) {
                    throw new Error();
                }
                gsap.timeline()
                    .to(content, { opacity: 0, duration: 0.3 })
                    .add(() => {
                        content.innerHTML = text;
                        Components.init(modal);
                    })
                    .to(content, { opacity: 1, duration: 0.3 });
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                request = null;
            });
    };

    const closeModal = (tween = true) => {
        if (!modalIsOpen) {
            return;
        }
        modalIsOpen = false;
        btn.setAttribute('aria-expanded', 'false');
        const afterClose = () => {
            Components.destroy(modal);
            modal.hidden = true;
            content.innerHTML = modalHtml;
            Viewport.releaseTabbing(btn);
        };
        if (tl) {
            tl.kill();
            tl = null;
        }
        if (request) {
            request.abort();
            request = null;
        }
        if (!tween) {
            afterClose();
            return;
        }
        tl = gsap.timeline({
            onComplete: afterClose
        })
            .to(modal, {
                opacity: 0,
                duration: 0.3
            }, 0);
    };

    const openModal = () => {
        if (modalIsOpen) {
            return;
        }
        modalIsOpen = true;
        modal.hidden = false;
        btn.setAttribute('aria-expanded', 'true');
        Viewport.lockTabbing(modal, closeBtn);
        if (tl) {
            tl.kill();
        }
        tl = gsap.timeline()
            .fromTo(modal, { opacity: 0 }, {
                opacity: 1,
                duration: 0.3
            }, 0);
        getHtml();
    };

    const onClick = e => {
        e.preventDefault();
        openModal();
    };

    const onBodyKeyUp = e => {
        if (!modalIsOpen) {
            return;
        }
        const key = e.key || e.keyCode;
        if (key === 'Escape' || key === 27) {
            closeModal();
        }
    };

    btn.addEventListener('click', onClick);
    closeBtn.addEventListener('click', closeModal);
    document.body.addEventListener('keyup', onBodyKeyUp);

    return {
        destroy() {
            closeModal(false);
            btn.removeEventListener('click', onClick);
            closeBtn.removeEventListener('click', closeModal);
            document.body.removeEventListener('keyup', onBodyKeyUp);
        }
    };

};
