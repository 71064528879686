// eslint-disable-next-line import/no-unresolved,import/no-webpack-loader-syntax
const loadMap = require('bundle-loader?lazy&name=map!../modules/Map');

export default (el, props) => {

    let map;
    let observer;

    const init = () => {
        observer = new IntersectionObserver(([{ isIntersecting }]) => {
            if (!isIntersecting) {
                return;
            }
            observer.disconnect();
            observer = null;
            loadMap(({ default: Map }) => {
                map = Map(el, props);
                map.init();
            });
        });
        observer.observe(el);
    };

    const destroy = () => {
        if (map) {
            map.destroy();
        }
        if (observer) {
            observer.disconnect();
            observer = null;
        }
    };

    return {
        init,
        destroy
    };
};
