import Dispatch from '../core/Dispatch';
import { ALERT_HIDDEN } from '../lib/events';

export default el => {

    const closeBtn = el.querySelector('[data-closebtn]');

    const { cookieName, cookieDuration } = el.dataset;

    const close = () => {
        el.hidden = true;
        document.body.classList.remove('has-alert');
        document.documentElement.style.setProperty('--alert', '0px');
        window.vrsg.setCookie(cookieName, '1', cookieDuration);
        Dispatch.emit(ALERT_HIDDEN);
    };

    closeBtn.addEventListener('click', close);

    return {
        destroy() {
            closeBtn.removeEventListener('click', close);
        }
    };

};
