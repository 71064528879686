import Flickity from 'flickity';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default (el, props = {}) => {

    const $el = $(el);
    const slider = $el.find('[data-slider]').get(0) || el;
    const prevBtn = $el.find('[data-nav="prev"]').get(0);
    const nextBtn = $el.find('[data-nav="next"]').get(0);

    let flkty;
    let { onChange = null, onReady = null } = props || {};
    if (onChange) {
        delete props.onChange;
    }
    if (onReady) {
        delete props.onReady;
    }

    const preloadNext = () => {
        const $nextSlide = flkty ? $(flkty.selectedCell.element.nextElementSibling) : null;
        if (!$nextSlide.length) {
            return;
        }
        $nextSlide.find('img:not(.lazyloading,.lazyloaded,.lazypreload').addClass('lazypreload');
    };

    const destroyFlickity = () => {
        if (!flkty) {
            return;
        }
        flkty.destroy();
        flkty = null;
        $(el).off('click');
        $(slider).off('focusin');
    };

    const createFlickity = () => {
        destroyFlickity();
        flkty = new Flickity(slider, {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            groupCells: true,
            prevNextButtons: false,
            pageDots: false,
            freeScroll: false,
            freeScrollFriction: 0.045,
            resize: false,
            adaptiveHeight: false,
            setGallerySize: true,
            wrapAround: false,
            accessibility: false,
            on: {
                dragStart() {
                    document.ontouchmove = e => e.preventDefault();
                },
                dragEnd() {
                    document.ontouchmove = () => true;
                },
                select() {
                    this.cells.forEach(({ element }) => {
                        element.removeAttribute('aria-hidden');
                    });
                    if (prevBtn && nextBtn) {
                        if (!this.options.wrapAround && this.selectedIndex <= 0) {
                            prevBtn.setAttribute('aria-disabled', 'true');
                            prevBtn.setAttribute('tabindex', '-1');
                        } else {
                            prevBtn.removeAttribute('aria-disabled');
                            prevBtn.removeAttribute('tabindex');
                        }
                        if (!this.options.wrapAround && this.selectedIndex >= this.slides.length - 1) {
                            nextBtn.setAttribute('aria-disabled', 'true');
                            nextBtn.setAttribute('tabindex', '-1');
                        } else {
                            nextBtn.removeAttribute('aria-disabled');
                            nextBtn.removeAttribute('tabindex');
                        }
                    }
                },
                change() {
                    preloadNext();
                    if (onChange) {
                        onChange(this);
                    }
                },
                ready() {
                    setTimeout(() => {
                        this.resize();
                        if (onReady) {
                            onReady(this);
                        }
                    }, 0);
                }
            },
            ...props
        });

        $(slider).on('focusin', 'a', e => {
            slider.parentNode.scrollLeft = 0;
            const { triggerTarget: link } = e;
            const cell = flkty.cells.find(({ element }) => element.contains(link));
            if (!cell) {
                return;
            }
            const index = flkty.cells.indexOf(cell);
            if (index > -1) {
                flkty.selectCell(index);
            }
        });

        $(el).on('click', '[data-nav="prev"]', () => {
            flkty.previous();
        });

        $(el).on('click', '[data-nav="next"]', () => {
            flkty.next();
        });

    };

    createFlickity();

    const onResize = () => {
        if (!flkty) {
            return;
        }
        setTimeout(() => {
            flkty.resize();
        }, 0);
    };

    Viewport.on('resize', onResize);

    let observer = new IntersectionObserver(([{ isIntersecting }]) => {
        if (!isIntersecting) {
            return;
        }
        preloadNext();
        observer.disconnect();
        observer = null;
    });

    observer.observe(slider);

    return {
        destroy() {
            destroyFlickity();
            Viewport.off('resize', onResize);
            if (observer) {
                observer.disconnect();
                observer = null;
            }
        }
    };

};
