import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { PROGRAMMATIC_SCROLL_END, PROGRAMMATIC_SCROLL_START } from './events';

gsap.registerPlugin(ScrollToPlugin);

export const getMaxScroll = () => (Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - Viewport.height);

/**
 * @param timeline
 * @param clearProps
 */
export const clearTimelineProps = (timeline, clearProps = 'all') => {
    const children = timeline.getChildren();
    children.forEach(child => {
        if (typeof child.targets === 'function') {
            const targets = child.targets().filter(target => typeof target !== 'function');
            if (!targets.length) {
                return;
            }
            gsap.set(targets, { clearProps });
        } else {
            clearTimelineProps(child, clearProps);
        }
    });
};

/**
 * @returns {boolean}
 */
export const isTouch = () => !document.documentElement.classList.contains('using-mouse');

/**
 * @param y
 * @returns {gsap.core.Tween}
 */
export const animatedScroll = (y, vars = {}, offsetY = 0) => {
    const alertHeight = parseInt(window.getComputedStyle(document.documentElement).getPropertyValue('--alert').replace('px', ''), 10);
    return gsap.to(window, {
        scrollTo: {
            y,
            offsetY: offsetY + alertHeight,
            autoKill: !isTouch(),
            onAutoKill() {
                Dispatch.emit(PROGRAMMATIC_SCROLL_END);
            }
        },
        duration: 0.75,
        ease: 'Cubic.easeInOut',
        onStart() {
            Dispatch.emit(PROGRAMMATIC_SCROLL_START);
        },
        onComplete() {
            Dispatch.emit(PROGRAMMATIC_SCROLL_END);
        },
        ...vars
    });
};
